<template>
    <div class="main">
        <div class="search">
            <div class="refresh" @click="getCodeList"><i class="fa fa-refresh"></i></div>
            <el-select v-model="code.byField" size="mini"
                       placeholder="검색조건"
                       style="width:120px;">
                <el-option label="전체" :value="null">전체</el-option>
                <el-option label="회원닉네임" :value="managerConst.ReferreCode.SEARCH_BYFEILD_USER">회원닉네임</el-option>
                <el-option label="총판닉네임" :value="managerConst.ReferreCode.SEARCH_BYFEILD_PARTNER">총판닉네임
                </el-option>
                <el-option label="코드" :value="managerConst.ReferreCode.SEARCH_BYFEILD_CODE">코드</el-option>
            </el-select>
            <el-input type="text" size="mini" v-model="code.searchString" placeholder="닉네임/코드"
                      style="width: 150px;margin-left: 5px"></el-input>
            <el-button type="primary" size="mini" style="margin-left: 5px" @click="search">검색
            </el-button>

            <el-select v-model="code.codeType" @change="getCodeList" size="mini"
                       placeholder="코드종류"
                       style="width:120px;margin-left: 20px">
                <el-option label="전체" :value="null">전체</el-option>
                <el-option label="회원" :value="managerConst.ReferreCode.CODE_TYPE_USER">회원코드</el-option>
                <el-option label="총판" :value="managerConst.ReferreCode.CODE_TYPE_PARTNER">총판코드</el-option>
                <el-option label="총판" :value="managerConst.ReferreCode.CODE_TYPE_MANAGER">관리자코드</el-option>
            </el-select>
            <el-select v-model="code.status" @change="getCodeList" size="mini"
                       placeholder="코드상태"
                       style="width:120px;margin-left: 5px">
                <el-option label="전체" :value="null">전체</el-option>
                <el-option label="정상" :value="managerConst.ENABLE">정상</el-option>
                <el-option label="차단" :value="managerConst.DISABLE">차단</el-option>
            </el-select>
        </div>

        <div class="data" style="overflow-y: scroll;max-height: 750px">
            <el-table
                    :data="codeList"
                    style="width: 100%"
                    max-height="750"
                    border>
                <el-table-column
                        fixed
                        label="코드"
                        width="150">
                    <template slot-scope="scope">
                        <el-input size="mini" v-model="scope.row.code" style="width: 80%"></el-input>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="닉네임"
                        width="200">
                    <template slot-scope="scope">
                                <span v-if="scope.row.codeType == managerConst.ReferreCode.CODE_TYPE_USER && scope.row.user "
                                      @click="editUser(scope.row.userId, managerConst.MODE_EDIT)"
                                      style="display: inline-block;width: 100%;padding: 2px 0;cursor: pointer">
                                    {{scope.row.user.nickname}}
                                </span>
                        <span v-if="scope.row.codeType == managerConst.ReferreCode.CODE_TYPE_PARTNER && scope.row.agent"
                              @click="editAgent(scope.row.partnerId, managerConst.MODE_EDIT)"
                              style="display: inline-block;width: 100%;padding: 2px 0;cursor: pointer" class="text-blue">
                                    {{scope.row.agent.nickname}}
                                </span>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="초기레벨"
                        width="200">
                    <template slot-scope="scope">
                                <span v-if="scope.row.codeType == managerConst.ReferreCode.CODE_TYPE_USER">
                                   <el-select v-model="scope.row.defaultRankId" size="mini"
                                              placeholder="초기레벨"
                                              style="width: 80%;">
                                        <el-option :label="rank.rankName" :value="rank.id" v-for="rank in rankList">{{rank.rankName}}</el-option>
                                    </el-select>
                                </span>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="초기그룹"
                        width="200">
                    <template slot-scope="scope">
                                <span v-if="scope.row.codeType == managerConst.ReferreCode.CODE_TYPE_USER">
                                   <el-select v-model="scope.row.defaultGroupId" size="mini"
                                              placeholder="초기레벨"
                                              style="width: 80%;">
                                        <el-option :label="item.groupName" :value="item.id" v-for="item in groupList">{{item.groupName}}</el-option>
                                    </el-select>
                                </span>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="상태"
                        width="200">
                    <template slot-scope="scope">
                        <el-select v-model="scope.row.status" size="mini"
                                   placeholder="상태" style="width: 80%;">
                            <el-option label="정상" :value="managerConst.ENABLE">정상</el-option>
                            <el-option label="차단" :value="managerConst.DISABLE">차단</el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="action"
                        width="80">
                    <template slot-scope="scope">
                        <el-button size="mini" type="primary" @click="updateCode(scope.row)">수정</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pagePanel">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNum"
                    :page-sizes="[20,50, 100, 150, 200]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageTotal">
            </el-pagination>
        </div>

    </div>
</template>

<script>

    import OnlineUserComp from "../../components/administrator/OnlineUserComp";
    import TodayStatisticComp from "../../components/administrator/TodayStatisticComp";
    import ManagerTopbarComp from "../../components/administrator/ManagerTopbarComp";
    import {Loading} from "element-ui";
    import {getHonorCasinoBetlist, sysData} from "../../network/manager/honorCasinoRequest";
    import {manager} from "../../common/administrator/managerMixin";
    import DateSelectorComp from "../../components/administrator/DateSelectorComp";
    import Vue from "vue";
    import UserEditToast from "../../components/administrator/UserPanel/UserEditToast";
    import {getRegisterCodeList, updateRegisterCode} from "../../network/manager/registerCodeRequest";
    import managerConst from "../../common/administrator/managerConst";
    import {getRankList} from "../../network/manager/rankRequest";
    import {getGroupList} from "../../network/manager/groupRequest";
    import PartnerEditToast from "../../components/administrator/UserPanel/PartnerEditToast";
    import AgentEditToast from "../../components/administrator/UserPanel/AgentEditToast";

    Vue.prototype.$userEditToast = UserEditToast
    Vue.prototype.$partnerEditToast = PartnerEditToast
    Vue.prototype.$agentEditToast = AgentEditToast
    export default {
        name: "ManagerRegisterCode",
        mixins: [manager],
        components: {
            DateSelectorComp,
            ManagerTopbarComp, TodayStatisticComp, OnlineUserComp
        },
        data() {
            return {
                startDate: '',
                endDate: '',
                code: {},
                codeList: [],
                pageNum: 1,
                pageSize: 20,
                pageTotal: 0,
                rankList: [],
                groupList: [],
            }
        },
        methods: {
            search(){
                this.code.codeType = null
                this.getCodeList()
            },
            getCodeList() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                getRegisterCodeList(this.code, this.pageNum, this.pageSize).then(res => {
                    this.codeList = res.data.data
                    this.pageTotal = res.data.total
                    loadingInstance.close()
                })
            },
            updateCode(item){
                updateRegisterCode(item).then(res=>{
                    if (res.data.success) {
                        this.$message({
                            type: 'success',
                            duration: 1000,
                            message: '업데이트 완료되였습니다'
                        });
                    } else {
                        this.$message({
                            showClose: true,
                            duration: 3000,
                            message: res.data.msg,
                            type: 'error'
                        });
                    }
                })
            },

            editUser(userId, mode) {
                this.$userEditToast({'userId': userId, 'mode': mode})
            },
            editAgent(partnerId, mode) {
                this.$agentEditToast({'agentId': partnerId, 'mode': mode})
            },
            handleSizeChange(val) {
                this.pageNum = 1;
                this.pageSize = val;
                this.getCodeList();
            },
            handleCurrentChange(val) {
                this.pageNum = val;
                this.getCodeList();
            },
            setStartDate(date) {
                this.startDate = this.$moment(date).format('yyyy-MM-DD');
            },
            setEndDate(date) {
                this.endDate = this.$moment(date).format('yyyy-MM-DD')
            },
        },
        created() {
            getRankList({'status': managerConst.ENABLE}, 1, 100).then(res => {
                this.rankList = res.data.data
            })
            getGroupList({'status': managerConst.ENABLE}, 1, 100).then(res => {
                this.groupList = res.data.data
            })
            this.code.status = managerConst.ENABLE
            this.getCodeList()
        },
    }
</script>

<style scoped>

</style>